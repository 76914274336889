import { createPortal } from "react-dom";
import useElementWhenReady from "../../hooks/useElementWhenReady";
import RepeatedTextBanner from "../../components/repeatedTextBanner/RepeatedTextBanner";

const RepeatedTextBannerPortal = () => {
  const portalElement = useElementWhenReady(
    "#early-access-product-banner-portal"
  );

  if (!portalElement) {
    return null;
  }

  return createPortal(
    <RepeatedTextBanner className="partOfProductGallery" />,
    portalElement
  );
};

export default RepeatedTextBannerPortal;
